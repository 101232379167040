.login-page header {
  width: 100%;
  height: 70px;
}
.login-page header .logo {
  float: left;
  margin-left: 40px;
  width: 162px;
  height: 22px;
}
.login-page header .logo img {
  width: 100%;
  height: 100%;
}
.login-page header .logo-title {
  float: left;
  margin-top: 20px;
  font-size: 22px;
  font-weight: 600;
}
.login-page header .phone {
  height: 22px;
  margin-top: 25px;
  margin-right: 40px;
  float: right;
  text-align: center;
}
.login-page header .phone span:nth-of-type(1) {
  font-size: 13px;
  color: #999999;
  vertical-align: middle;
}
.login-page header .phone span:nth-of-type(2) {
  font-size: 18px;
  color: #333333;
  vertical-align: middle;
}
.login-page header .top-logo {
  height: 24px;
  margin-top: 25px;
  float: right;
  margin-right: 24px;
}
.login-page header .top-logo img {
  width: 100px;
  height: 100%;
  vertical-align: middle;
}
.login-page header .top-logo span {
  font-size: 13px;
  color: #999999;
  vertical-align: middle;
}
.login-page section {
  width: 100%;
  background: url(/dist/daotian/3fc9be4f669bea143d04dd9efc5f1a38.png) no-repeat;
  background-size: cover;
}
.login-page section .section-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.login-page section .section-content .word {
  margin-top: 90px;
  display: inline-block;
  vertical-align: top;
}
.login-page section .section-content .word .name {
  font-size: 45px;
  font-weight: bolder;
  color: #ffffff;
}
.login-page section .section-content .word .line {
  margin-top: 24px;
  width: 140px;
  height: 0;
  border: 3px solid #FFD500;
}
.login-page section .section-content .word .slogan-one {
  font-size: 16px;
  color: #ffffff;
  margin-top: 36px;
}
.login-page section .section-content .word .slogan-two {
  font-size: 16px;
  color: #ffffff;
  margin-top: 16px;
}
.login-page section .section-content .sign-in {
  display: inline-block;
  vertical-align: top;
  margin-left: 83px;
  width: 500px;
  height: 340px;
  background-color: #ffffff;
  border-radius: 4px;
}
.login-page section .section-content .sign-in .accountLogin {
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-bottom: 2px solid #e5e5e5;
}
.login-page section .section-content .sign-in .accountLogin p {
  font-size: 16px;
  color: #666666;
  padding-left: 20px;
}
.login-page section .section-content .sign-in .input {
  height: 50px;
  margin-left: 45px;
  background-color: #fafafa;
  border: 1px solid #e5e5e5;
}
.login-page section .section-content .sign-in .input-phone {
  width: 260px;
  margin-top: 30px;
}
.login-page section .section-content .sign-in .input-password {
  width: 410px;
  margin-top: 15px;
}
.login-page section .section-content .sign-in .input::-moz-placeholder {
  color: #999999;
  font-size: 14px;
}
.login-page section .section-content .sign-in .input::-webkit-input-placeholder {
  color: #999999;
  font-size: 14px;
}
.login-page section .section-content .sign-in .input:-ms-input-placeholder {
  color: #999999;
  font-size: 14px;
}
.login-page section .section-content .sign-in .code-button {
  width: 140px;
  height: 50px;
  margin-top: 30px;
  margin-left: 2px;
  font-size: 14px;
  color: #333333;
  background-color: #fafafa;
  border: 1px solid #e5e5e5;
}
.login-page section .section-content .sign-in .submit-button {
  width: 410px;
  height: 50px;
  margin-left: 45px;
  margin-top: 25px;
  background-color: #FFD500;
  font-size: 14px;
  color: #333333;
  border: 1px solid #e2770e;
}
.login-page footer {
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #999999;
}
.login-page footer p {
  margin-top: 40px;
}
.login-page footer a {
  color: #999999;
}
.login-page footer a:hover {
  color: #999999;
}
.login-page .refresh-login {
  background-color: #FFFFFF;
  width: 189px;
  cursor: pointer;
  height: 185px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-page .refresh-login .mask {
  position: absolute;
  background: #FFFFFF;
  width: 189px;
  height: 185px;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #666666;
  font-size: 21px;
}
